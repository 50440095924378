.footer {
    background-color: #265f8e;
    color: #fff;
    text-align: center;
    padding: 40px 20px;
    position: relative;
    overflow: hidden;
    box-shadow: 0 -5px 15px rgba(0, 0, 0, 0.5); /* Shadow at the top */
  }
  
  .footer::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 80%);
    opacity: 0.6;
    animation: shimmer 5s infinite;
  }
  
  .footer-content {
    position: relative;
    z-index: 2;
  }
  
  .contact-items {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    flex-wrap: wrap; /* Wraps items for better responsiveness */
  }
  
  .contact-item {
    margin: 0 15px;
    text-align: center;
  }
  
  .contact-item a {
    text-decoration: none;
    color: #010c0e;
    position: relative;
    display: inline-block; /* Aligns icon with text */
    padding: 10px; /* Adds space around the link for better touch interaction */
  }
  
  .icon {
    width: 50px;
    height: auto;
    margin-bottom: 5px;
  }
  
  .footer-copy {
    font-size: 0.9rem;
    opacity: 0.8;
    margin-top: 20px; /* Adds space before the copyright text */
  }
  
  @keyframes shimmer {
    0% { transform: translateX(-50%); }
    100% { transform: translateX(100%); }
  }
  
  @media (max-width: 768px) {
    .contact-items {
      flex-direction: row;
    }
  
    .contact-item {
      margin: 10px 0;
    }
  }
  