.App {
  text-align: center;
  background: linear-gradient(135deg, #6dd5ed, #2193b0);
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
  color: white;
}

/* Style for the loading container */
.loading-container {
  position: fixed; 
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
}

footer {
  background-color: #333;
  color: white;
  text-align: center;
  padding: 10px 0;
}


.fade-out {
  animation: fadeOut 1s;
  animation-fill-mode: forwards;
}

@keyframes fadeOut {
  from { opacity: 1; }
  to { opacity: 0; visibility: hidden; }
}
