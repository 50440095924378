.projects-section {
    background-color: #152441;
    color: white;
    padding: 50px;
    text-align: center;
    position: relative;
  }
  
  .projects-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
  }
  


  .project-card {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 10px;
    width: 80%;
    background: linear-gradient(145deg, #1b1e22, #042130);
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.7);
    transition: transform 0.5s ease, box-shadow 0.5s ease;
    position: relative;
    opacity: 0;
    transform: translateY(80px);
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  }
  
  .project-card.visible {
    opacity: 1;
    transform: translateY(0);
  }


  .project-card:hover {
    transform: translateY(-15px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.8);
  }
  
  .project-image img {
    width: 100%;
    height: 100%;
    display: block;
  }
  
  .project-info {
    padding: 20px;
    background: rgba(0, 0, 0, 0.5);
    border-top: 3px solid #6dd5ed;
  }
  
  .project-info h3 {
    margin-top: 0;
    color: #6dd5ed;
    font-size: 2.2rem;
    font-family: 'Poppins', sans-serif; /* Add your preferred font here */
  }
  
  .project-info h4 {
    color: darkcyan;
    font-size: 1.2rem;
    font-weight: bold;
  }
  
  .project-info p {
    font-size: 1rem;
    color: #ccc;
    text-align: justify;
  }
  
  .project-links {
    margin-top: 15px;
  }
  
  .project-links a {
    text-decoration: none;
    color: #6dd5ed;
    margin-right: 10px;
    transition: color 0.3s ease;
    display: inline-block;
    padding: 8px 20px;
    background-color: #1a1c1d;
    border-radius: 25px;
    font-weight: bold;
    letter-spacing: 1px;
  }
  
  .project-links a:hover {
    color: #fff;
    background-color: #6dd5ed;
  }


/* SVG Animations styling */
.svg-animations {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none; /* Prevent SVGs from blocking other elements */
  z-index: -1; /* Ensure they sit behind the project cards */
}

/* Horizontal line across project cards */
.connecting-line {
  position: absolute;
  top: 50%; /* Adjust position as needed */
  left: 0;
  animation: connectLine 8s infinite ease-in-out;
  opacity: 0.9;
}

/* Vertical line to connect project cards */
.connecting-vertical-line {
  position: absolute;
  top: 0;
  left: 50%;
  animation: connectLine 8s infinite ease-in-out;
  opacity: 0.9;
}

/* Connection circles positioned at desired nodes */
.connection-circle {
  position: absolute;
  animation: connectCircle 8s infinite ease-in-out;
  opacity: 0.9;
}

/* Position specific circles for connecting appearance */
.connection-circle:nth-child(3) {
  top: 20%;
  left: 30%;
}

.connection-circle:nth-child(4) {
  top: 70%;
  left: 70%;
}

/* Animations for pulsing effect */
@keyframes connectLine {
  0%, 100% {
      opacity: 0.9;
  }
  50% {
      opacity: 1;
  }
}

@keyframes connectCircle {
  0%, 100% {
      transform: scale(1);
      opacity: 0.9;
  }
  50% {
      transform: scale(1.2);
      opacity: 1;
  }
}

/* Project card hover effect */
.project-card:hover {
  transform: translateY(-15px) scale(1.05);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.8);
}




@media screen and (max-width: 768px) {
    .project-card {
      /* Adjust grid layout for tablets */
      grid-template-columns: 1fr;
      width: 90%;
      margin-bottom: 30px;
    }
  
    .project-info h3 {
      font-size: 1.8rem;
    }
  
    .project-info h4 {
      font-size: 1rem;
    }
  
    .project-info p {
      font-size: 0.9rem;
    }
  }
  
  @media screen and (max-width: 480px) {
    .projects-container {
      /* Adjust container layout for mobile phones */
      gap: 20px;
    }
  
    .project-card {
      width: 100%;
      transform: translateY(50px);
      transition: opacity 0.6s ease-out, transform 0.6s ease-out;
    }
  
    .project-info h3 {
      font-size: 1.5rem;
    }
  
    .project-info h4 {
      font-size: 0.9rem;
    }
  
    .project-info p {
      font-size: 0.8rem;
    }

    .project-links a{
      margin-top: 10px;  
    }
  }
  
  