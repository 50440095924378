@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');

/* Contacts Section */
.contacts-section {
  padding: 50px;
  background: rgb(11, 37, 83);
  color: #333;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
}

.contact-methods {
  margin-bottom: 40px;
  font-size: 1.1rem;
}

/* Contact Form */
.contact-form {
  background: lightcyan;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  display: block;
  margin-left: 150px;
  margin-right: 150px;
}

.contact-form h3 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #385bab;
}

.contact-form form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.contact-form form input,
.contact-form form textarea {
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
}

.contact-form form button {
  padding: 15px 30px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.contact-form form button:hover {
  background-color: #45a049;
}

.contact-form form input:focus,
.contact-form form textarea:focus {
  outline: none;
  border-color: #4CAF50;
  box-shadow: 0 0 0 2px rgba(76, 175, 80, 0.2);
}

/* Responsive adjustments for Tablets */
@media (max-width: 768px) {
  .contact-form {
    margin-left: 50px;
    margin-right: 50px;
    padding: 20px;
  }

  .contact-form h3 {
    font-size: 1.8rem;
  }

  .contact-form form input,
  .contact-form form textarea,
  .contact-form form button {
    font-size: 0.9rem;
  }
}

/* Responsive adjustments for Mobile Phones */
@media (max-width: 480px) {
  .contact-form {
    margin-left: 20px;
    margin-right: 20px;
    padding: 15px;
  }

  .contact-form h3 {
    font-size: 1.6rem;
  }

  .contact-form form input,
  .contact-form form textarea,
  .contact-form form button {
    font-size: 0.8rem;
  }
}
