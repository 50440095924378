.home-container {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(135deg, #032f39, #25778c);
}

.threejs-scene {
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%); /* Center the scene */
    width: 100%;
    height: 100%;
    z-index: 1;
}

.home-content {
    position: relative;
    z-index: 10;
    display: flex;
    align-items: center;
    gap: 20px;
    flex-direction: column;
    animation: float 5s ease-in-out infinite;
    padding: 20px; 
}

.home-text {
    font-size: 1rem;
    color: #fff;
    background: rgba(0, 0, 0, 0.7);
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
    text-shadow: 2px 2px 10px rgba(255, 255, 255, 0.7);
    border: 1px solid #6dd5ed;
    backdrop-filter: blur(5px);
    text-align: center;
    transform: rotate(0deg); /* Remove rotation on smaller screens */
}

.home-image {
    margin-top: 20px; /* Reduce top margin */
    max-width: 300px; /* Smaller image size for mobile */
    width: 80%; /* Responsive width */
    border-radius: 50%;
    border: 5px solid white;
    box-shadow: 0px 0px 10px 0px rgba(255, 255, 255, 0.5);
    margin-bottom: 20px;
}

@keyframes float {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-20px); }
}

/* Media Query for Larger Screens */
@media (min-width: 768px) {
    .threejs-scene {
        display: block;
        left: 200px; /* Reset position for larger screens */
        transform: translateX(0); /* Remove centering */
    }

    .home-content {
        flex-direction: row; /* Side-by-side layout */
    }

    .home-text {
        font-size: 1.5rem; /* Larger font size */
        padding: 20px; /* Larger padding */
        transform: rotate(-5deg); /* Apply rotation */
    }

    .home-image {
        margin-top: 100px;
        max-width: 500px; /* Larger image size */
    }
}

@media (max-width: 768px) {
    .threejs-scene {
        top: 200px; /* Adjust top position for mobile */
        width: 100%; /* Increase width for better visibility */
        height: 50%; /* Adjust height */
        left: 50%;
        transform: translateX(-50%); /* Keep centered */
    }
    
}



